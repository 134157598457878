.su-progress-bar {
  position: relative;
  background-color: $bg-color-light-grey;
  font-size: 0.8rem;
  z-index: 1;
  height: 1rem;

  .su-progress-bar-bg {
    position: absolute;
    z-index: 2;
    height: 100%;
    width: 100%;
    background-color: $color-orange;
  }

  .su-progress-bar-data {
    background-color: transparent;
    position: absolute;
    height: 100%;
    width: 100%;
    padding: 0.25rem 0.5rem;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-black;
  }

}