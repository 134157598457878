@import "styles/colors.scss";

.su-video {
  width: 100%;
  position: relative;
  display: block;
  &:before {
    content: '';
    float: left;
    padding-bottom: 56.25%;
  }

  video {
    width: 100%;
    height: 100%;
    background-color: $color-white;
  }
}

.su-vimeo {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  &:before {
    content: '';
    float: left;
    padding-bottom: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-white;
  }
  
}