.su-nugget-status {

  &.su-size-mini {
    margin-top: 2px;
  }

  &.su-size-medium {
    display: inline-flex;
    align-items: center;

    .label {
      font-size: 0.8rem;
    }
    img {
      width: 93px;
      height: 40px;
    }
  }

  &.su-size-large {
    display: inline-block;

    .label {
      display: block;
      font-size: 1rem;
    }
    img {
      width: 185px;
      height: 81px;
    }
  }
}

.su-nugget{
  .card-header{
    height: 100%;
    font-size: 0.9rem;
  }
}