@import "styles/colors.scss";
@import "styles/font.scss";
@import "styles/blocks/employee-happiness.scss";
@import "styles/blocks/card.scss";
@import "styles/blocks/journey-list.scss";
@import "styles/blocks/progress-bar.scss";
@import "styles/blocks/nav-tabs.scss";
@import "styles/blocks/su-login-form.scss";
@import "styles/blocks/su-form.scss";
@import "styles/blocks/btn-group.scss";
@import "styles/blocks/avatar.scss";
@import "styles/blocks/modal.scss";
@import "styles/blocks/su-team-editor.scss";
@import "styles/blocks/su-nugget-status.scss";
@import "styles/blocks/header-profile.scss";
@import "styles/blocks/su-tool-polls.scss";
@import "styles/blocks/su-navbar.scss";
@import "styles/blocks/su-markdown.scss";

:root {
    --global--color-primary: $color-black;
    --orange: $color-orange;
    --heading--font-size-h1: 3rem;
    --heading--font-size-h2: 2rem;
}

body {
    font-family: "Open Sans", sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: bold;
        font-family: "Titillium Web", sans-serif;
    }
}

.color-orange {
    color: $color-orange !important;
}

.color-petrol {
    color: $color-petrol !important;
}

.color-light-grey {
    color: $color-light-grey !important;
}

.color-dark-grey {
    color: $color-dark-grey !important;
}

.bg-white {
    background-color: $color-white !important;
}

.bg-light-grey {
    background-color: $bg-color-light-grey !important;
}

.bg-orange {
    background-color: $color-orange !important;
}
.bg-green-dark {
    background-color: $color-green-dark !important;
}
.text-green-dark {
    color: $color-green-dark !important;
}

.font-size-0 {
    font-size: 0.7rem !important;
    line-height: 0.9rem !important;
}
.font-size-1 {
    font-size: 0.8rem !important;
    line-height: 1rem !important;
}
.font-size-2 {
    font-size: 0.9rem !important;
    line-height: 1.1rem !important;
}
.font-size-3 {
    font-size: 1rem !important;
    line-height: 1.2rem !important;
}
.font-size-4 {
    font-size: 1.2rem !important;
    line-height: 1.5rem !important;
}
.font-size-5 {
    font-size: 1.5rem !important;
    line-height: 1.75rem !important;
}

.su-todo {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    &:before {
        content: "todo (missing feature): ";
    }
}

@keyframes suplaceholdershimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}

h1,
h2,
h3 {
    font-weight: bold;
    font-family: "Titillium Web", sans-serif;
}

a {
    color: $color-orange;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

// custom wp-elementor styles (table, lists)
/**
   * Table styling
   * DO NOT CHANGE THIS FILE!
   * To override any of the settings in this section, add your styling code in the custom directory.
   */
table {
    background-color: transparent;
    width: 100%;
    margin-bottom: 15px;
    font-size: 0.9em;
    border-spacing: 0;
    border-collapse: collapse;
}
table th,
table td {
    padding: 15px;
    line-height: 1.5;
    vertical-align: top;
    border: 1px solid #80808080;
}
table th {
    font-weight: bold;
}
table thead th,
table tfoot th {
    font-size: 1em;
}
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table caption + thead tr:first-child th,
table caption + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table colgroup + thead tr:first-child th,
table colgroup + thead tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td,
table thead:first-child tr:first-child th,
table thead:first-child tr:first-child td {
    border-top: 1px solid #80808080;
}
table tbody > tr:nth-child(odd) > td,
table tbody > tr:nth-child(odd) > th {
    background-color: #80808012;
}
table tbody tr:hover > td,
table tbody tr:hover > th {
    background-color: #8080801a;
}
table tbody + tbody {
    border-top: 2px solid #80808080;
}
@media (max-width: 767px) {
    table table {
        font-size: 0.8em;
    }
    table table th,
    table table td {
        padding: 7px;
        line-height: 1.3;
    }
    table table th {
        font-weight: 400;
    }
}

/**
   * List styling
   * DO NOT CHANGE THIS FILE!
   * To override any of the settings in this section, add your styling code in the custom directory.
   */
dl,
dt,
dd,
ol,
ul,
li {
    margin-top: 0;
    margin-bottom: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    vertical-align: baseline;
    background: transparent;
}

.form-control[type="file"] {
    border-bottom: 0;

    &::file-selector-button {
        border: 0 !important;
        border-radius: 0;
        font-family: "Titillium Web", sans-serif;
        font-weight: bold;
        font-size: 0.8rem;
        padding: 0.5rem 2rem;
        display: inline-block;
        background-color: $color-dark-grey !important;
        color: $color-white !important;
    }
}

.su-table{
    td, th {
      border-width: 0 0 1px 0 !important;
      padding: 0.75rem;
    }
}

.btn {
    border: 0 !important;
    border-radius: 0;
    font-family: "Titillium Web", sans-serif;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 0.5rem 2rem;
    display: inline-block;
    white-space: nowrap;

    &.btn-small {
        font-size: 0.8rem;
    }

    &.btn-width75 {
        width: 75%;
    }
    &.btn-w-icon {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    &.btn-primary {
        background-color: $color-orange !important;
        color: $color-white !important;
    }

    &.btn-secondary {
        background-color: $color-dark-grey !important;
        color: $color-white !important;
    }
    &.btn-delete {
        background-color: $color-white !important;
        color: $color-red !important;
        border: 2px solid $color-red !important;
    }
}

.su-border {
    border: 2px solid $color-black;
    position: relative;
    left: -15px;
    margin-left: 30px;
    margin-top: 1rem;

    &.mb-5 {
        margin-bottom: calc(3rem + 30px) !important;
    }
    &.mb-4 {
        margin-bottom: calc(2rem + 30px) !important;
    }
    &.mb-3 {
        margin-bottom: calc(1rem + 30px) !important;
    }
    &.mt-5 {
        margin-top: calc(3rem + 30px) !important;
    }
    &.mt-4 {
        margin-top: calc(2rem + 30px) !important;
    }
    &.mt-3 {
        margin-top: calc(1rem + 30px) !important;
    }

    &:before {
        content: " ";
        position: absolute;
        left: -15px;
        top: -15px;
        height: 80%;
        width: 80px;
        z-index: -1;
        background-color: $color-light-orange;
    }

    &.su-border-plain {
        &:before {
            display: none;
        }
    }

    &.su-border-top {
        &:before {
            height: 40px;
            width: 80%;
        }
    }

    &.su-with-border-headline {
        margin-top: 4rem;
    }

    .su-border-headline {
        position: absolute;
        width: 100%;
        top: -50px;
        font-size: 2rem;
        color: $color-orange;
        text-align: center;
        z-index: 2;
    }

    > * {
        position: relative;
        top: 15px;
        left: 15px;
        z-index: 2;
    }
}

ul.su-navigation-list {
    list-style: none;
    min-width: 380px;
    z-index: 2;
    margin: 0;
    padding: 0;

    li {
        background-color: $color-white;
        padding: 0;
    }

    a {
        padding: 1rem;
        border: 2px solid transparent;
        color: $color-black;
        display: block;

        &:hover {
            border: 2px solid $color-orange;
            text-decoration: none;
            color: $color-orange;
        }
    }
}

.font-su {
    font-family: "Titillium Web", sans-serif;
}

.su-clickable {
    cursor: pointer;
}

.su-invert {
    filter: invert(1);
}

.su-icon {
    padding: 2px;
    display: inline-flex;
    line-height: 1.2rem;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        height: 100%;
    }

    &.su-icon-blue {
        background-color: $color-blue;
        img {
            filter: invert(1);
        }
    }

    &.su-icon-orange {
        background-color: $color-orange;
        img {
            filter: invert(1);
        }
    }

    &.su-icon-grey {
        background-color: $color-light-grey;
        img {
            filter: invert(1);
        }
    }
}

.su-table-action-icon {
    background-color: transparent;
    border-radius: 3px;
    padding: 4px;
    cursor: pointer;
    display: inline-block;
    line-height: 12px;

    img {
        width: 12px;
        height: 12px;
        display: inline-block;
    }

    &:hover {
        img {
            filter: invert(1);
        }
        background-color: $color-orange;
        color: white !important;
    }

    &.action-icon-big {
        img {
            width: 16px;
            height: 16px;
        }
    }
}

.su-placeholder {
    display: inline-block;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: suplaceholdershimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    width: inherit;
    position: relative;
    overflow: hidden;
    color: transparent !important;
    border-color: transparent !important;
}

.box-shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.feedback-success {
    color: black;
    background-color: lightgreen;
    border: 1px solid green;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
}

.feedback-error {
    color: black;
    background-color: lightcoral;
    border: 1px solid red;
    font-size: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
}

.file-info {
    font-size: 0.9rem;
    color: gray;
}

.cursor-pointer {
    cursor: pointer;
}

.text-muted {
    margin-top: 1.5rem !important;
    margin-bottom: 0;
    font-size: 0.8rem;
}

// extra file for collapsable newTeam

.content {
    //border: 1px solid rgb(128, 123, 123);
    padding: 0.5rem;
    border-radius: 5px;
}

.content-parent {
    height: 0px;
    overflow: hidden;
    transition: height ease 0.9s;
}

.w-padding {
    padding: 16px !important;
}

//general classes for positioning of icons

.icon-left {
    margin-right: 0.5rem;
}
.icon-right {
    margin-left: 0.5rem;
}

.w-300px {
    width: 300px;
}

// QR-Code
.qr-code {
    height: 220px;
    width: 220px;
    //max-height: 250px;
    //max-width: 250px;
}

.adjustm {
    margin-left: -8px;
}

//Helper for adjusting content between su-cards that have su-border-headline
.ml-adjust-pseudo {
    margin-left: 34px;
}

.filter-grayscale {
    filter: grayscale(1);
}
.filter-su {
    filter: invert(60%) sepia(100%) saturate(650%) hue-rotate(343deg) brightness(100%) contrast(93%);
}
.filter-white {
    filter: invert(96%) sepia(100%) saturate(0%) hue-rotate(140deg) brightness(106%) contrast(104%);
}

.card--inactive {
    filter: grayscale(1);
    opacity: 0.8;
    background-color: #ddd;

    .card {
        background-color: #eee;
    }
}

@media (min-width: 768px) {
    #su-account-editor form,
    #su-profile-editor form {
        min-width: 50vw;
    }
}
@media (min-width: 992px) {
    #su-account-editor form,
    #su-profile-editor form {
        min-width: 30vw;
    }
}
