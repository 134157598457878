.su-avatar {
  border-radius: 50% !important;
  display: inline-block;

  &.su-avatar-sm {
    border: 1px solid black;
    width: 25px;
    height: 25px;
    align-self: center;
    vertical-align: -30%;
    object-fit: cover;

    &.su-avatar-placeholder {
      background: $color-white url("../images/user.svg") center center no-repeat;
      background-size: 14px 14px;
    }

  }

  &.su-avatar-lg {
    border: 1px solid black;
    width: 65px;
    height: 65px;
    max-width: inherit;
    max-height: inherit;
    object-fit: cover;

    @media (min-width: 992px) {
      width: 130px;
      height: 130px;
      border: 2px solid black;
    }

    &.su-avatar-upload {
      background: $color-white url("../images/image-upload.svg") center center no-repeat;
      background-size: 60px 60px;
    }

    &.su-avatar-placeholder {
      background: $color-white url("../images/user.svg") center center no-repeat;
      background-size: 60px 60px;

      &:hover {
        background: $color-white url("../images/image-upload.svg") center center no-repeat;
        background-size: 60px 60px;
      }
    }

  }
}.su-avatar {
  border-radius: 50% !important;
  display: inline-block;

  &.su-avatar-sm {
    border: 1px solid black;
    width: 25px;
    height: 25px;
    align-self: center;
    vertical-align: -30%;
    object-fit: cover;

    &.su-avatar-placeholder {
      background: $color-white url("../images/user.svg") center center no-repeat;
      background-size: 14px 14px;
    }

  }

  &.su-avatar-lg {
    border: 1px solid black;
    width: 65px;
    height: 65px;
    max-width: inherit;
    max-height: inherit;
    object-fit: cover;

    @media (min-width: 992px) {
      width: 130px;
      height: 130px;
      border: 2px solid black;
    }

    &.su-avatar-upload {
      background: $color-white url("../images/image-upload.svg") center center no-repeat;
      background-size: 60px 60px;
    }

    &.su-avatar-placeholder {
      background: $color-white url("../images/user.svg") center center no-repeat;
      background-size: 60px 60px;

      &:hover {
        background: $color-white url("../images/image-upload.svg") center center no-repeat;
        background-size: 60px 60px;
      }
    }

  }
}