.su-form {

  label {
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
  }

  input {
    border-radius: 0;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 1px solid $color-black;
    padding-left: 0;
    padding-right: 0;
    font-family: 'Titillium Web', sans-serif;

    &:focus {
      outline: none;
      -webkit-box-shadow: none;
      box-shadow: none;
      color: $color-orange !important;
      border-bottom: 1px solid $color-orange;
    }
  }
}