.nav-tabs {
  font-size: 1.1rem;
  font-weight: bold;
  border-bottom: 1px solid $color-orange;

  @media (min-width: 992px) {
    font-size: 1.5rem;
  }

  .nav-link {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-bottom: 5px solid transparent;
    color: $color-black;

    &:hover {
      color: $color-orange;
    }

    &.disabled {
      opacity: 0.5;
      color: $color-light-grey;
      &:hover {
        color: $color-light-grey;
      }
    }

    &.active {
      background-color: transparent;
      color: $color-black;
      border-bottom: 5px solid $color-orange !important;
    }
  }
}