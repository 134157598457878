$color-white: #fff;
$color-black: #282828;
$color-petrol: #87cbd6;
$color-light-grey: #868686;
$color-dark-grey: #5e5e5e;
$bg-color-light-grey: #e7e7e7;
$color-orange: #f59926;
$color-light-orange: #f9b563;
$color-blue: #87cbd6;
$bg-color-blue: #87cbd6;
$color-green: #43ba34;
$color-green-dark: #25641d;
$color-green: #a30004;
$color-red: #ff5151;