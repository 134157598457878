.su-navbar {
  color: $color-black !important;
  font-weight: bold !important;
  background-color: white;

  button:hover {
    background-color: white;
    color: $color-black !important;
  }
  button:focus {
    background-color: white;
    color: $color-black !important;
  }

  .navbar-nav .nav-link {
    color: $color-black !important;
    font-weight: bold !important;
    @media (max-width: 991.98px) {
    border-bottom: 1px solid rgba(132, 132, 132, 0.15);
    }
  }

  .nav-link:focus {
    color: $color-orange !important;
  }
  .nav-link:hover {
    color: $color-orange !important;
  }
  .navbar-collapse {
    &.show {
      -webkit-box-shadow: 0px 15px 15px -15px rgba(45, 62, 68, 0.1);
      -moz-box-shadow: 0px 15px 15px -15px rgba(45, 62, 68, 0.1);
      box-shadow: 0px 15px 15px -15px rgba(45, 62, 68, 0.1);
    }
  }

  .navbar-toggler {
    color: $color-black !important;
    font-weight: bold !important;
    border: none !important;

    button:hover {
      color: $color-black !important;
      font-weight: bold !important;
      background-color: none !important;
    }
    button:focus {
      color: $color-black !important;
      font-weight: bold !important;
      background-color: none !important;
    }

    span {
      color: $color-black !important;
      border: none;
      background-image: url('../images/cross.svg');
    }

    &.collapsed {
      span {
        color: $color-black !important;
        border: none;
        background-image: url('../images/hamburger.svg');
      }
    }
  }

  .dropdown-toggle::after {
    display: none;
  }

  .dropdown-menu.show {
    background-color: white;
    border: none;
    border-radius: 0;
    box-shadow: 0 0 0 0;
    margin-top: 0;
    padding: 0;

    .dropdown-item {
      color: $color-black !important;
      font-weight: bold !important;
      padding: 13px 20px 13px 20px;
      border-bottom: 1px solid rgba(132, 132, 132, 0.15);

      &:hover {
        background-color: white;
        color: $color-orange !important;
      }
    }
  }

  .nav-contact {
    border: 2px solid black;
    padding: 8px 16px !important;

    @media (max-width: 991.98px) {
      border: none;
      padding: 8px 0px !important;

      &:hover {
        border: none;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
      }
    }

    @media (min-width: 992px) {
      margin-left: 1rem;

      &:hover {
        border: 2px solid $color-orange !important;

        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
      }
    }
  }
}
