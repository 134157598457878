#su-employee-happiness-survey {
  .su-question {
    font-size: 1.5rem;
    font-style: italic;
    display: block;

    &:before {
      content: "“";
    }
    &:after {
      content: "”";
    }
  }
}