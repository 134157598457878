.su-login-form {
  .card-header {
    font-size: 2rem;
    color: $color-orange;
    text-align: center;
    font-weight: normal;
    font-family: "Titillium Web", sans-serif;
    padding: 0.5rem 0 0 0;
  }
  .card-body {
    min-width: 380px;
  }

  label {
    font-weight: bold;
    margin-bottom: 0;
  }
}