#su-tool-polls {
  .su-unverified-entry {
    opacity: 0.25;
  }

  td,
  th {
    border-width: 0 0 1px 0;
    padding: 0.75rem;
  }
}

//poll input styles

//hover orange
.hover-orange {
  :hover {
    color: orange !important;
  }
  button:hover {
    color: #fff !important;
    background-color: orange !important;
  }
}
//focus-visible orange for input fields (text, dropdown)
.focus-visible-orange {
  &:focus-visible {
    outline-color: #f59926 !important;
  }
}

.default-w-poll-input {
  width: 100%;
  min-width: 250px;
  @media (min-width: 576px) {
    min-width: 400px;
  }
}

//Stars hover
.star-on {
  color: orange !important;
}
.star-off {
  color: #000 !important;
}

// Slider Styles

.su-slider {
  input[type='range']::-webkit-slider-thumb {
    background: orange !important;
  }
  input[type='range']::-moz-range-thumb {
    background: orange !important;
  }
  input[type='range']::-ms-thumb {
    background: orange !important;
  }

  min-width: 275px;
  @media (min-width: 576px) {
    min-width: 350px;
  }
}
.range-slider__wrap {
  width: 100% !important;
}

// Poll Dropdown Styles
.select-center {
  text-align-last: center;
}
.poll-dropdown {
  background: #5e5e5e;
  color: white;
  max-width: 200px;

  /*&.has-value {
    background: orange;
  }*/

  &:focus-visible {
    outline: none;
  }
}
