//styles for text rendered with React Markdown
@import "styles/colors.scss";

.su-markdown {
    /*overwrite bootstrap font-sizes because of nesting (these are currently used in the app)
    h1 Bootstrap heading (2.5rem = 40px)
    h2 Bootstrap heading (2rem = 32px)
    h3 Bootstrap heading (1.75rem = 28px)
    h4 Bootstrap heading (1.5rem = 24px)
    h5 Bootstrap heading (1.25rem = 20px)
    h6 Bootstrap heading (1rem = 16px)*/
  
    h1 {
      font-size: 2rem
    }
    h2 {
        font-size: 1.75rem
      }
    h3 {
        font-size: 1.5rem
    }
    h4 {
        font-size: 1.25rem
      }
    h5 {
      font-size: 1rem
      }
      
}  