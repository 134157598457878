.btn-group {
  &.su-btn-group-bw {
    button {
      background-color: transparent;
      border: 1px solid $color-black !important;
      font-weight: normal;
      color: $color-black;
      border-radius: 0;

      &.active {
        background-color: $color-orange;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }
}