.su-journey-list {
  width: 100%;

  &.su-journey {
    
    //hover border
    &:hover {
      border: 2px solid $color-black;
      //margin-top: -2px;
      position: relative;
      
      //change position of all children, then border like in Figma, Problem 2nd hover effect on button
      /*> * {
        position: relative;
        top: 15px;
        left: 15px;
        z-index: 2;
      }*/ 

    &:before {
      content: ' ';
      position: absolute;
      left: -15px;
      top: -15px;
      height: 80%;
      width: 80px;
      z-index: -1;
      background-color: $color-light-orange;
      
    }
    
    }
  }

  &.su-size-small {

    .su-journey {
      display: block;

      .su-journey-image {
        display: none;
      }

      .su-journey-meta {
        display: block;
      }

      .su-journey-title {
        font-size: 1rem;
        line-height: 1.2rem;
        min-width: 250px;
      }

      .su-journey-description {
        display: none;
      }

      .su-journey-cta {
        display: none;
      }
    }
  }

  &.su-size-medium {

    .su-journey {
      display: flex;
      margin-bottom: 3rem;

      .su-journey-image {
        width: 3.25rem;
        height: 3.25rem;
        margin-right: 1rem;
        flex-grow: 0;
      }

      .su-journey-meta {
        width: 100%;
        flex-grow: 1;
        overflow: hidden;
      }

      .su-journey-title {
        width: 100%;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        height: 1.5rem;
      }

      .su-journey-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
        height: 0.75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .su-journey-cta {
        display: none;
      }
    }
  }

  &.su-size-large {

    .su-journey {
      display: flex;
      flex-direction: column;
      margin-bottom: 3rem;

      .su-journey-image {
        width: 100%;
        height: 220px;
        margin-bottom: 1rem;
      }

      .su-journey-meta {
        overflow: hidden;
      }

      .su-journey-title {
        display: block;
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        height: 1.5rem;
      }

      .su-journey-description {
        display: block;
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 0.5rem;
        min-height: 1rem;
      }

      .su-journey-cta {
        font-size: 0.75rem;
        display: inline-block;
        border: 1px solid orange;
        color: orange;
        padding: 0.25rem 0.5rem;
        border-radius: 5px;
      }
    }
  }

  &.su-size-grid {
    display: flex;
    width: 100%;
    justify-content: flex-start;

    .su-journey {
      flex: 1 0 auto;
      height: auto;
      min-width: 25%;
      max-width: 25vw;
      position: relative;
      margin: 0 1rem 1rem 0;

      &:before {
        content: '';
        display: block;
        padding-top: 100%;
      }

      .su-journey-image {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }

      .su-journey-meta {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;
      }

      .su-journey-title {
        display: block;
        overflow: hidden;
        color: #fff;
        &:not(.su-placeholder) {
          padding: 0.5rem;
          background-color: rgba(0,0,0,0.75);
        }
      }

      .su-journey-description {
        display: none;
      }

      .su-journey-cta {
        display: none;
      }
    }
  }
}