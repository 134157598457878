@import "styles/colors.scss";

.su-quote {
  background-color: $bg-color-light-grey;
  position: relative;
  overflow: hidden;
  min-height: 130px;

  h2 {
    text-align: center;
    z-index: 2;
    position: relative;
  }

  p {
    z-index: 2;
    position: relative;
    padding-left: 8rem;
  }

  &:before {
    content: '"';
    font-size: 20rem;
    line-height: 18rem;
    font-weight: bold;
    color: $color-light-orange;
    position: absolute;
    top: -1rem;
    left: 15px;
    z-index: 1;
  }
}