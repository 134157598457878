.modal-dialog {
  .modal-content {
    background-color: transparent;
    border: 2px solid $color-black;
    border-radius: 0;
    padding: 15px;

    &:before {
      content: " ";
      position: absolute;
      left: -15px;
      bottom: -15px;
      height: 180px;
      width: 80px;
      z-index: 1;
      background: url("../images/su-border-orange.png") top left no-repeat;
    }

    .modal-header,
    .modal-body,
    .modal-footer {
      z-index: 2;
      border: 0;
      border-radius: 0;
      background-color: $color-white;
      padding-left: 2rem;
      padding-right: 2rem;
    }

    .modal-header {
      color: $color-orange;
      display: block;
      padding-top: 2rem;

      .modal-title {
        display: block;
        text-align: center !important;
        font-family: 'Titillium Web', sans-serif;
      }
    }

    .modal-footer {
      padding-top: 0;
      padding-bottom: 2rem;
      justify-content: center;
    }

  }
}