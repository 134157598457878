.card {
  border: 0;
  background-color: $color-white;
  border-radius: 0;
  text-decoration: none !important;
  color: $color-black !important;
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;

  .card-header {
    background-color: $color-white;
    font-weight: bold;
    font-family: 'Titillium Web', sans-serif;
    font-size: 1rem;
    border-bottom: 0;

    .su-header-meta {
      font-size: 0.8rem;
      color: $color-orange;
      font-weight: normal;
    }
  }

  &.su-nugget,
  &.su-journey {
    .card-img-top {
      height: 180px;
      width: 100%;
      object-fit: cover;
      z-index: 1;
      position: relative;
    }
  }

  .su-card-hover {
    position: absolute;
    background-color: rgba(0,0,0,0.75);
    display: none;
    height: 180px;
    width: 100%;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }

  &:hover {
    .su-card-hover {
      display: flex;
    }
  }

  &[data-status="checked"] {
    border: 2px solid $color-orange !important;
  }

}