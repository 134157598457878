.header-profile {

    h1 {
      font-family: "Titillium Web", Sans-serif;
    font-size: 4rem;
    font-weight: 700;
    letter-spacing: -1px;
    margin-top: 0px;
    color: #000000 !important;
    }

    h1 span {
        margin-left: 1rem;
    }
    a {
      text-decoration: none;
      font-weight: 700;
      
    }
  
    a:hover {
      cursor:pointer;
     }
     
    nobr {
      margin-left: 0.5rem;
    }
    
  }
  