/* titillium-web-300 - latin */
@font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 300;
    src: local(''),
         url('fonts/titillium-web-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-300italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 300;
    src: local(''),
         url('fonts/titillium-web-v15-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-regular - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 400;
    src: local(''),
         url('fonts/titillium-web-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 400;
    src: local(''),
         url('fonts/titillium-web-v15-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-600 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 600;
    src: local(''),
         url('fonts/titillium-web-v15-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-600italic - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: italic;
    font-weight: 600;
    src: local(''),
         url('fonts/titillium-web-v15-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* titillium-web-700 - latin */
  @font-face {
    font-family: 'Titillium Web';
    font-style: normal;
    font-weight: 700;
    src: local(''),
         url('fonts/titillium-web-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
         url('fonts/titillium-web-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }